export default {
  SEARCH_BOX_PLACEHOLDER: 'Search keyword, category, brand or part',
  SEARCH_BOX_BUTTON_CLASS: 'btn-submit',

  SHOW_ALL_BUTTON_CLASS: 'cm_btn cm_button cm_button__secondary',

  SELECT_YOUR_VEHICLE: 'Select Your Ford',
  SELECT_YOUR_VEHICLE_BLOCK: 'Select Your <span>Ford</span>',
  VERIFY_FITMENT_TITLE: 'Verify fitment with your Ford',

  GARAGE_ICON_CONTAINER_CLASS: '',
  GARAGE_SIZE: '<span class="count enj-cartcount" key="garage-size">{{size}}</span>',
};
