//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-632:_2516,_7620,_7796,_6480,_9143,_6732,_2479,_4576;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-632')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-632', "_2516,_7620,_7796,_6480,_9143,_6732,_2479,_4576");
        }
      }catch (ex) {
        console.error(ex);
      }